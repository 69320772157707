<template>
	<div class="container">
		<div class="header-top">
			<div class="top-content">
				<div class="top-left">
					<router-link to="/" class="logo-wrap">
						<img src="@/assets/images/logo.jpg" />
					</router-link>
				</div>
				<div class="top-center">
					数字仓库（{{store_name}}）
				</div>
				<div class="top-right">
					<div class="right_label">搜索范围</div>
					<div class="right_item" :class="{active:radiusIndex==index}" @click="radiusClick(index)"
						v-for="(item,index) in radiusList" :key="index">
						{{item.text}}
					</div>
				</div>
			</div>
		</div>
		<div id="allmap" class="allmap" />
		<el-dialog title="本仓储本类物品详细的数据登记信息" :center="true" width="80%" :visible.sync="goodsDetail">
			<template v-if="goodsData">
				<div v-for="(item,index) in goodsData.goods" :key="index">
					<el-descriptions :column="2" border v-if="index==(currentPage-1)">
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="企业名称:">
							{{goodsData.name}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="社会信用代码:">
							{{goodsData.unicode}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="注册地址:"
							:span="2">
							{{goodsData.province_name}}{{goodsData.city_name}}{{goodsData.district_name}}{{goodsData.address}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="注册地址:"
							:span="2">
							{{item.house_address}}
						</el-descriptions-item>
					</el-descriptions>
					<el-descriptions class="mydes" :column="2" border v-if="index==(currentPage-1)">
						<el-descriptions-item label-class-name="my-label " style="margin-top: 20px;"
							content-class-name="my-content" label="产品名称:" :span="2">
							{{item.goods_name}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="产品标准号:">
							{{item.bz_number}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="生产许可证号:">
							{{item.number}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="产品执行标准:">
							{{item.zxbz}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="批准文号:">
							{{item.approve_nuber}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="卫生标准:">
							{{item.bz_health}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="环保标识:">
							{{item.hb_logo}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="有机标识:">
							{{item.organic_logo==1?'有':'无'}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="有效期:">
							{{item.expire_data}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="产品等级:">
							{{item.level}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="包装（内）:">
							{{item.inside_package}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="包装（外）:">
							{{item.outside_package}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="外包装数量单位:">
							{{item.package_unit}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="外包装长*宽*高:">
							{{item.package_area}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="即时最大供应量:">
							{{item.js_supply}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="24小时内最大供应量:">
							{{item.twenty_four_supply}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="72小时内最大供应量:">
							{{item.seventy_two_supply}}
						</el-descriptions-item>
						<el-descriptions-item label-class-name="my-label" content-class-name="my-content"
							label="240小时内最大供应量:">
							{{item.two_hundred_forty_supply}}
						</el-descriptions-item>
					</el-descriptions>

				</div>
				<div class="pager">
					<el-pagination :pager-count="5" :total="total" :current-page.sync="currentPage"
						:page-size.sync="pageSize" layout="prev,pager,next"
						@current-change="handleCurrentPageChange"></el-pagination>
				</div>
			</template>

		</el-dialog>
		<el-dialog title="供给能力" :center="true" width="80%" :visible.sync="totalDetail">
			<template v-if="totalData">
				<el-descriptions :column="2" border>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="分类名称:">
						{{totalData.category_name}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="分类编号:">
						{{totalData.category_id}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="供给企业数量:"
						:span="2">
						{{totalData.companyLength}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="即时物品供给数量:"
						:span="2">
						{{totalData.js_supply}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="24小时物品供给数量:"
						:span="2">
						{{totalData.twenty_four_supply}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="72小时物品供给数量:"
						:span="2">
						{{totalData.seventy_two_supply}}
					</el-descriptions-item>
					<el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="240小时物品供给数量:"
						:span="2">
						{{totalData.two_hundred_forty_supply}}
					</el-descriptions-item>
				</el-descriptions>

			</template>

		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		indexGoods
	} from '@/api/supply/supply';
	import {
		BMapLoader
	} from '@/utils/bdmap'
	export default {
		name: "supplyszckmap",
		components: {},
		data: () => {
			return {
				map: null,
				loading: true,
				goodsData: null,
				orderList: [],
				category_id: "",
				lon: 116.404,
				lat: 39.915,
				radiusIndex: 3,
				radiusList: [{
						text: "5km",
						value: 5
					},
					{
						text: "10km",
						value: 10
					},
					{
						text: "50km",
						value: 50
					},
					{
						text: "100km",
						value: 100
					}
				],
				BMAP: null,
				goodsDetail: false,
				currentPage: 1,
				pageSize: 1,
				total: 0,
				totalData: null,
				totalDetail: false,
				store_name:""
			};
		},
		destroyed() {
			// this.map.destroy()
		},
		created() {
			this.category_id = this.$route.query.cate_id
			this.store_name = this.$route.query.name
			this.initMap()

			// this.getOrderList();
		},
		methods: {
			centerPointClick() {
				console.log(this.totalData)
				this.totalDetail = true
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				// this.refresh();
			},
			EntMarkerClick(index) {
				console.log(index)
				this.currentPage = 1
				this.goodsData = this.orderList[index]
				this.goodsDetail = true
				this.total = this.goodsData.goods.length
			},
			radiusClick(index) {
				let BMAP = this.BMAP
				this.radiusIndex = index
				let scalvalue = 13
				if (this.radiusIndex == 1) {
					scalvalue = 13
				}
				if (this.radiusIndex == 2) {
					scalvalue = 12
				}
				if (this.radiusIndex == 3) {
					scalvalue = 10
				}
				if (this.radiusIndex == 4) {
					scalvalue = 9
				}
				this.map.centerAndZoom(new BMAP.Point(this.lon, this.lat), scalvalue);
				this.initmap(new BMAP.Point(this.lon, this.lat), BMAP);
			},
			initmap(centerPoint, BMap) {
				var circle;
				var myIcon = new BMap.Icon(require("@/assets/images/supply/markers1.png"), new BMap
					.Size(25,
						25), {
						anchor: new BMap.Size(10, 25),
						// imageOffset: new BMap.Size(0, 0 - 11 * 25)
					});
				this.map.clearOverlays();
				// 添加圆形覆盖物
				// 创建圆形覆盖物中心点标注对象并添加到地图
				var marker = new BMap.Marker(centerPoint, {
					icon: myIcon
				});
				let that = this
				// 中心点添加点击事件
				marker.addEventListener("click", function(e) {
					that.centerPointClick();
				});

				this.map.addOverlay(marker);
				//创建圆对象，参数半径-单位米
				circle = new BMap.Circle(centerPoint, this.radiusList[this.radiusIndex].value * 1000, {
					fillColor: "#FA430A",
					// 填充色
					strokeColor: "#FA430A",
					// 边线色
					strokeWeight: 2,
					// 边线的宽度，以像素为单位。
					fillOpacity: 0.1 // 填充的透明度，取值范围0 - 1。
				});
				this.map.addOverlay(circle);
				this.getOrderList()
				// $.each(data, function(index, value) {
				// 	if (BMapLib.GeoUtils.isPointInCircle(new BMap.Point(value.lng, value
				// 			.lat), circle)) {
				// 		entcount += 1;
				// 		supply3 += value.supply3;
				// 		supply24 += value.supply24;
				// 		supply72 += value.supply72;
				// 		supply240 += value.supply240;
				// 		// 企业标注点
				// 		marker = new BMap.Marker(new BMap.Point(value.lng, value.lat));
				// 		// 添加标注点点击事件
				// 		marker.addEventListener("click", function(e) {
				// 			EntMarkerClick(value.uid);
				// 		});
				// 		map.addOverlay(marker);
				// 	}
				// });
			},
			initMap() {
				this.$nextTick(() => {
					BMapLoader('BaudVlM3HIGrOFosAdXGlRrxuzWU5B2W').then(BMap => {
						this.BMAP = BMap
						const map = new BMap.Map('allmap', {
							enableMapClick: false
						})
						this.map = map
						map.disableKeyboard() // 禁用键盘操作地图
						map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
						// 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
						const centerPoint = new BMap.Point(this.lon, this.lat)
						// map.centerAndZoom(point, 15)


						// 圆形覆盖物

						// 圆形覆盖物半径
						// var centerPoint = new BMap.Point(116.404, 39.915);
						// 创建点坐标
						// 标注点图标

						var entcount, supply3, supply24, supply72, supply240;
						var goodsList;
						var goodsIndex = 0;
						this.map.addOverlay(new BMap.Marker(centerPoint), );
						// 标注添加到地图
						this.map.centerAndZoom(centerPoint, 9);
						// 初始化地图，设置中心点坐标和地图级别
						this.map.enableScrollWheelZoom(true);
						// 开启鼠标滚轮缩放
						// map.addControl(new BMap.NavigationControl(opts));
						// 平移缩放
						// this.map.addControl(new BMap.OverviewMapControl());
						// 默认位于地图右下方，是一个可折叠的缩略地图
						let that = this
						this.map.addEventListener("click", function(e) {
							// 点击覆盖物，但不是圆形覆盖物
							if (e.overlay && e.overlay.toString() != '[object Circle]') {
								return;
								// 退出
							}
							that.lat = e.latlng.lat
							that.lon = e.latlng.lng
							that.initmap(e.latlng, BMap);
						});
						this.initmap(centerPoint, BMap);

						// 查询范围改变
						// $(".search_scope a").click(function() {
						//     if (!$(this).hasClass("active")) {
						//         $(".search_scope a").removeClass("active");
						//         $(this).addClass("active");
						//         var toRadius = Number($(this).attr("data"));
						//         switch (toRadius) {
						//         case 5000:
						//             radius = 5000;
						//             map.centerAndZoom(centerPoint, 13);
						//             initmap(centerPoint);
						//             break;
						//         case 10000:
						//             radius = 10000;
						//             map.centerAndZoom(centerPoint, 12);
						//             initmap(centerPoint);
						//             break;
						//         case 50000:
						//             radius = 50000;
						//             map.centerAndZoom(centerPoint, 10);
						//             initmap(centerPoint);
						//             break;
						//         case 100000:
						//             radius = 100000;
						//             map.centerAndZoom(centerPoint, 9);
						//             initmap(centerPoint);
						//             break;
						//         }
						//     }
						// });

					})
				})
			},
			getOrderList() {
				let BMap = this.BMAP
				indexGoods({
						category_id: this.category_id,
						lat: this.lat,
						lon: this.lon,
						range: this.radiusList[this.radiusIndex].value
					})
					.then(res => {
						let data = res.data
						if (data != null) {
							this.orderList = data
							console.log(this.orderList)
							if (this.orderList.length > 0) {
								let js_supply = 0,
									twenty_four_supply = 0,
									seventy_two_supply = 0,
									two_hundred_forty_supply = 0
								this.orderList.forEach((item, index) => {
									let that = this
									let marker = new BMap.Marker(new BMap.Point(item.longitude - 0, item
										.latitude -
										0));
									// 添加标注点点击事件
									marker.addEventListener("click", function(e) {
										that.EntMarkerClick(index);
									});
									console.log(item, 123)
									this.map.addOverlay(marker);
									item.goods.forEach((it) => {
										js_supply += (it.js_supply - 0)
										twenty_four_supply += (it.twenty_four_supply - 0)
										seventy_two_supply += (it.seventy_two_supply - 0)
										two_hundred_forty_supply += (it.two_hundred_forty_supply - 0)
									})
								})
								this.totalData = {
									category_name: this.$route.query.name,
									category_id: this.$route.query.cate_id,
									companyLength: this.orderList.length,
									js_supply,
									twenty_four_supply,
									seventy_two_supply,
									two_hundred_forty_supply,
								}

							} else {
								this.totalData = {
									category_name: this.$route.query.name,
									category_id: this.$route.query.cate_id,
									companyLength: 0,
									js_supply: 0,
									twenty_four_supply: 0,
									seventy_two_supply: 0,
									two_hundred_forty_supply: 0,
								}
							}
							console.log(this.totalData)
							// $.each(data, function(index, value) {
							// 	if (BMapLib.GeoUtils.isPointInCircle(new BMap.Point(value.lng, value
							// 			.lat), circle)) {
							// 		entcount += 1;
							// 		supply3 += value.supply3;
							// 		supply24 += value.supply24;
							// 		supply72 += value.supply72;
							// 		supply240 += value.supply240;
							// 		// 企业标注点
							// 		marker = new BMap.Marker(new BMap.Point(value.lng, value.lat));
							// 		// 添加标注点点击事件
							// 		marker.addEventListener("click", function(e) {
							// 			EntMarkerClick(value.uid);
							// 		});
							// 		map.addOverlay(marker);
							// 	}
							// });
						}
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
		}
	};
</script>
<style scoped>
	/deep/.nowidth .el-form-item__content {
		width: 360px;
	}

	.el-select {
		width: 120px;
	}

	/deep/.el-select .el-input.is-focus .el-input__inner {
		border-color: #11773a;

	}

	.el-select-dropdown__item.selected {
		color: #11773a !important;
	}

	/deep/.el-select .el-input__inner:focus {
		border-color: #11773a;

	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #11773a;
	}

	/deep/.el-pager li.active {
		color: #11773a;
		;
		cursor: default;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
		color: #fff !important;
	}

	/deep/.el-pager .number:hover {
		color: #11773a !important;
	}

	/deep/.my-label {
		font-size: 16px;
		font-weight: bold !important;
		color: #333;
		width: 18%;
	}

	/deep/.goodsname {
		margin-top: 20px;
	}

	/deep/.my-content {
		font-size: 16px;
		color: #333;
	}
</style>
<style scoped lang="scss">
	.el-button--primary {
		background-color: #57c5a5;
		border-color: #57c5a5;
	}

	.header-top {
		width: 100%;
		height: 80px;
		font-size: 12px;
		background-color: #fff;

		.el-dropdown {
			font-size: $ns-font-size-sm;
		}

		.top-content {
			// width: $width;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			width: 90%;
			margin: 0 auto;

			.top-center {
				font-size: 25px;
				font-weight: bold;
			}

			.top-left {
				// height: 100%;
				line-height: 31px;

				.logo-wrap {
					// width: 240px;
					height: 60px;
					line-height: 60px;
					display: block;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
			}

			.top-right {
				font-size: 15px;
				color: #666;
				font-weight: 600;
				display: flex;

				.active {
					background-color: $base-supply-color;
					color: #fff;
				}

				a {
					float: left;
					line-height: 31px;
					padding: 0 10px;

					&:hover {
						color: $base-supply-color;
						font-size: 16px;
						font-weight: bold;
					}
				}

				div {
					float: left;
					height: 100%;
					margin-left: 10px;
					cursor: pointer;
					line-height: 31px;
					padding: 0 5px;

					&.member-info {
						margin-left: 0;

						span:first-child {
							margin-right: 10px;
						}
					}

					span:hover {
						color: $base-supply-color;
						font-size: 16px;
						font-weight: bold;
					}

					&.el-dropdown:hover {
						background-color: #fff;
					}
				}
			}
		}
	}



	.el-pagination {
		text-align: center;
		margin-top: 20px;
	}


	.container {
		display: flex;
		flex-direction: column;
		height: 100vh;

		.allmap {
			width: 100%;
			flex: 1;
		}

		.mydes {
			margin-top: 30px;
		}

	}
</style>